
/*
 * By Suxue
 * 2021/10/21
 */
// import { Checkbox, CheckboxGroup, Popup, Dialog, Toast } from "vant";
import BannerTop from "@/components/BannerTop/index.vue";
// import {
//   getweixinUserWrongbook,
//   getweixinUserWrongbookArchiveAll,
//   getweixinUserWrongbookArchiveDelAll,
// } from "@/api/mistakes";
// import { mapGetters } from "vuex";
import { Toast, Dialog, CheckboxGroupInstance, CheckboxInstance } from "vant";
import { getOssImgCutUrl } from "@/static/js/oss";
import {
  API,
  changeWrongQuestionArchiveStatus,
  genPdfWrongQuestions,
  listWrongBookQuestions,
} from "../../API/wrongbook";
import { defineComponent } from "vue";
import VideoExplainPlayer from "@/project/smartHomeworkUserClient/component/VideoExplainPlayer/index.vue";
// import { checkIsHasSameQuestion } from "@/pages/answer/route/sameQuestion/function";
// import Skeleton from "@/components/Skeleton";
// import SkeletonItem from "@/components/Skeleton/item";
// import { getUserWrongQus } from "@/api/explain";
// import { checkPrintBlank } from "@/ProductAuthority/SPU/downloadBook";
// import { onlySvModal } from "@/ProductAuthority/Popup";

export default defineComponent({
  name: "WrongBookList",
  components: {
    VideoExplainPlayer,
    BannerTop,
  },
  data() {
    return {
      scrollQuestionId: "",
      hasScrolled: false,
      tag: true,
      animationEnd: false,
      activeIcon:
        "https://img01.yzcdn.cn/upload_files/2022/05/10/Ftyb7CHsy0SJ-iUbaNm_FYDmEVrx.png",
      activeIcon1:
        "https://img01.yzcdn.cn/upload_files/2021/10/29/FuEdxPPyn8LYlEVH6j4wVITZhjbf.png",
      inactiveIcon:
        "https://img01.yzcdn.cn/upload_files/2022/05/25/FojJKVMZjZEszz_kEt_dGXYs2Ydj.png",
      inactiveIcon1:
        "https://img01.yzcdn.cn/upload_files/2021/10/29/FmUWc42llh5Y94iYgzow-THf9Uzx.png",
      checked: false,
      grade: "",
      volume: "",
      course: "",
      isPulling: false,
      mistakesData: [] as API.DATA_LIST_WRONG_BOOK_QUESTION[],
      isEdit: false,
      editMod: "",
      selectQuestionIds: [] as number[],
      isSelectAll: false, // 选择全部
      selectPrintModShow: false,
      printShowAnswer: true,
      printPopup: false,
      pdfHref: "",
    };
  },
  computed: {
    // ...mapGetters(["userGrade", "openid", "userInfo", "userTerm"]),
  },
  watch: {
    selectQuestionIds: function (newVal) {
      const mistakesData = this.mistakesData;
      if (newVal.length === mistakesData.length) {
        this.isSelectAll = true;
      } else {
        this.isSelectAll = false;
      }
    },
  },
  created() {
    const { courseVal, gradeVal, volumnVal } = this.$route.params as {
      courseVal: string;
      gradeVal: string;
      volumnVal: string;
    };
    // const { grade, volume, course, questionId } = this.$route.query;
    this.grade = gradeVal;
    this.volume = volumnVal;
    this.course = courseVal;
    // this.scrollQuestionId = questionId;
  },
  async mounted() {
    this.isPulling = true;
    if (this.scrollQuestionId) {
      await this.initScrollQuestionId();
    }
    this.getMistakesList();
  },
  methods: {
    iknow() {
      window.open(this.pdfHref);
    },
    changeTag(val: boolean) {
      this.tag = val;
      this.getMistakesList();
    },
    getWrongBookImg(
      item: API.DATA_LIST_WRONG_BOOK_QUESTION,
      attach: API.ATTACH,
      answer: boolean | undefined
    ) {
      return item.sourceType === "smthw"
        ? getOssImgCutUrl(attach, answer)
        : `/cms/` + (answer ? attach.answerPicPath : attach.blankPicPath);
    },
    getRenderQuestionName(item: API.DATA_LIST_WRONG_BOOK_QUESTION) {
      const bookName = item.preferenceBookName || item.bookName || "";
      const pageName = `第${item.attaches[0].pageNo}页`;
      const questionName = item.questionNo;
      return bookName + " " + pageName + " " + questionName;
    },

    changeCheck() {
      if (this.checked) {
        // if (checkPrintBlank(this.userInfo)) {
        this.checked = false;
        // } else {
        // onlySvModal({
        // urlPath:
        // "/cms/webapp/home/vipPay/index.html?channel=cuotiben_kda_gn#/gv",
        // });
        // }
      } else {
        this.checked = true;
      }
    },
    editClear() {
      if (this.mistakesData.length < 1) {
        return Toast("当前分类下暂无错题！");
      }
      this.isEdit = true;
      this.editMod = "clear";
    },
    editPrint() {
      if (this.mistakesData.length < 1) {
        return Toast("当前分类下暂无错题！");
      }
      this.isEdit = true;
      this.editMod = "print";
    },
    exit() {
      this.isEdit = false;
      this.isSelectAll = false;
      this.selectQuestionIds = [];
      this.pdfHref = "";
    },
    toggleQuesCheckBox(item: API.DATA_LIST_WRONG_BOOK_QUESTION) {
      const eleQestionRefs = this.$refs[
        "QuesCheckBoxRef" + item.id
      ] as unknown[] as CheckboxInstance[];
      if (eleQestionRefs && eleQestionRefs.length > 0) {
        eleQestionRefs[0].toggle();
      }
    },
    toggleSelectAll() {
      const eleQuestionGroupRef = this.$refs
        .VanCheckboxGroup as CheckboxGroupInstance;
      if (eleQuestionGroupRef) {
        eleQuestionGroupRef.toggleAll(this.isSelectAll);
      }
    },
    // 初始化滚动id，和标签
    async initScrollQuestionId() {
      // const res = await getUserWrongQus({
      //   openid: this.openid,
      //   questionIds: [this.scrollQuestionId],
      // });
      // const data = res.data[0] || {};
      // this.tag = data.archived === "N";
    },
    // 获取错题列表数据
    async getMistakesList() {
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      try {
        this.isPulling = true;
        // const { grade, openid, volume, course } = this;
        await this.$store.dispatch(
          "smartHomeworkUserClient/user/getStudentUserInfo",
          true
        );

        const { openid, appid } =
          this.$store.state.smartHomeworkUserClient.user.studentUserInfo;
        if (!openid || !appid) return;
        this.mistakesData = await listWrongBookQuestions(
          this.course,
          this.grade,
          this.volume,
          this.tag ? "N" : "Y",
          appid,
          openid
        );
        this.mistakesData.map((item) => {
          return {
            ...item,
            show: true,
          };
        });
        //     if (this.scrollQuestionId && !this.hasScrolled) {
        //       this.$nextTick(() => {
        //         const ele = document.querySelector(
        //           "#questionItem" + this.scrollQuestionId
        //         );
        //         if (ele) {
        //           setTimeout(() => {
        //             ele.scrollIntoView({ behavior: "smooth" });
        //             this.hasScrolled = true;
        //           }, 500);
        //         }
        //       });
        //     }
        //   }
        // });
      } finally {
        this.isPulling = false;
        toast.clear();
      }
    },
    async learned(item: API.DATA_LIST_WRONG_BOOK_QUESTION, status = 0 | 1) {
      // const params = {
      //   ids: selectQuestionIds.join(), // 学科ID
      //   ops: Number(this.tag),
      // };
      // getweixinUserWrongbookArchiveAll(params).then((res) => {
      //   this.selectQuestionIds = [];
      //   if (this.tag) {
      //     Toast({ duration: 1000, message: "已添加到已掌握" });
      //     this.getMistakesList();
      //   } else {
      //     Toast({ duration: 1000, message: "已添加到未掌握" });
      //     this.getMistakesList();
      //   }
      // });
      try {
        const ret = await changeWrongQuestionArchiveStatus(
          [item.wrongBookId],
          status
        );
        this.mistakesData = this.mistakesData.filter((_item) => {
          return _item.wrongBookId !== item.wrongBookId;
        });
        Toast("错题移入已掌握");
      } catch (e) {
        Toast("错题移入已掌握");
      }
    },
    // 查看详情到求讲解页面
    toExplain(item: API.DATA_LIST_WRONG_BOOK_QUESTION) {
      if (item.sourceType === "smthw") {
        (this.$refs as any).VideoExplainPlayer.open({
          bookId: item.bookId,
          chapterId: item.chapterId,
          questionId: item.id,
        });
      }
    },
    // 删除错题
    // delMistakes() {
    //   const selectQuestionIds = this.selectQuestionIds;
    //   if (selectQuestionIds.length < 1) {
    //     return Toast("请选择题目！");
    //   }
    //   Dialog.confirm({
    //     title: `已选中(${selectQuestionIds.length})道题`,
    //     message: "删除后不可恢复，确定要删除吗？",
    //     confirmButtonColor: "#EF4246",
    //     width: "7.6rem",
    //   })
    //     .then(() => {
    //       // getweixinUserWrongbookArchiveDelAll(selectQuestionIds.join()).then(
    //       //   (res) => {
    //       //     Toast({ duration: 1000, message: "删除成功" });
    //       //     this.selectQuestionIds = [];
    //       //     this.exit();
    //       //     this.getMistakesList();
    //       //   }
    //       // );
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       Toast({ duration: 1000, message: "取消删除" });
    //     });
    // },
    viewPrint() {
      console.log(this.selectQuestionIds);
      if (this.selectQuestionIds.length < 1) {
        return Toast("请选择题目！");
      }
      this.toPrint();

      // this.selectPrintModShow = true;
    },
    hidePrintAnswer() {
      // if (checkPrintBlank(this.userInfo)) {
      //   this.printShowAnswer = false;
      // } else {
      //   onlySvModal({
      //     urlPath:
      //       "/cms/webapp/home/vipPay/index.html?channel=cuotiben_dyys_gn#/gv",
      //   });
      // }
    },
    async toPrint() {
      const toast = this.$toast.loading({
        message: "生成中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      try {
        const { openid, appid } =
          this.$store.state.smartHomeworkUserClient.user.studentUserInfo;
        if (!openid || !appid) return;
        const pdfHref = await genPdfWrongQuestions(
          this.selectQuestionIds,
          (this.$route.query.name as string) ||
            `${this.grade}${this.course}错题册`,
          this.checked ? 1 : 0,
          openid
        );
        this.pdfHref = pdfHref.data;
        this.printPopup = true;
        // window.open(pdfHref);
      } finally {
        toast.clear();
      }
    },
  },
});
